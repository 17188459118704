<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="cultural-healing-practices.png">
    <template v-slot:title>Explore cultural healing practices </template>
    <template v-slot:subtitle>
      Ceremonies and other cultural practices play an important role in the
      overall wellbeing of Indigenous people. Ceremonial gatherings may last for
      days or weeks. Through participation in songs, prayer, music, drumming and
      dance the family and community contribute to your healing energy of
      individuals.</template
    >
  </Banner>
  <!-- Help Myself  -->
  <section class="talk-sect md:my-12 md:py-1s4">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="max-w-6xl px-4 mx-auto sm:px-6">
        <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
          Here are some cultural practices you can engage in for your wellbeing.
        </div>
      </div>

      <div class="pt-8">
        <!-- 1 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Talk to an elder</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  Elders are non-judgmental and excellent listeners. They are
                  the “Gatekeepers” of Indigenous wisdom, knowledge, and
                  history. Elders have a good understanding of self, shaped by
                  life experience and knowledge of tradition and culture. They
                  are deeply committed to share their knowledge, provide
                  guidance, and teachings to respect the natural world. An elder
                  can help you with smudges, sweats, prayers or other
                  traditional supports on your journey to emotional well-being.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />
        <!-- 2 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Smudge</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  A smudge is a purification ceremony and involves burning one
                  of our sacred medicines (for example, sage) to cleanse your
                  body, mind and spirit. Smudge cleanses and sanctifies you and
                  you invite the spirits and ancestors to join you. You can use
                  smudge to rid yourself or your space — of negativity. This
                  includes past traumas, bad experiences, or negative energies
                  from others. It allows you to stop, slow down, connect to the
                  ancestors and become more grounded.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 3 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Sweat lodge</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  The sweat lodge is used for cleansing, healing and prayer.
                  Sweats are also used to seek spiritual solutions to challenges
                  and a way to let go off hurt and pain. Different types of
                  lodges are constructed for different purposes and different
                  tribes and communities follow different protocols for
                  construction of a lodge and its ceremony.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 4 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Connection to the land</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  The land is pure, it contains the food, and medicine. It is
                  connected to our spiritual beliefs, traditional knowledge and
                  teachings. Our connection to land gives us our identity and a
                  sense of belonging.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 5 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Sundance</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  Sundance is traditionally a celebration of community
                  well-being, renewal, and thanksgiving. It was and is one of
                  the most sacred ceremonies. It is a sacred place to fast,
                  purify, be safe and learn. The dance is a way to be close to
                  the Creator. Gifts are offered to the Creator and commit to
                  important cultural values such as hope, peace, and harmony.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 6 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Powwow</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  The powwow celebrates Indigenous people traditions through
                  singing, dancing, and drumming. The powwow takes place in a
                  circle, it is a is a way to connect with others. Youth are
                  often recognized during these ceremonies. Elders, families,
                  and communities gather to support and celebrate with youth.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />
        <!-- 7 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Dancing, singing & drumming</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  Drumming is very sacred; it is a way to invite the spirit – it
                  is very healing. Songs and dance are sacred, and the drum beat
                  itself is the heartbeat of Mother Earth. When you dance it
                  helps you to feel free and let go and connect with the
                  ancestors and spirit.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 7 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Fasting</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  Fasting this consists of giving up food and sometimes water
                  for up to four days to help cleanse our system and bring us
                  closer to our ancestors through sacrifice and prayer.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 8 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Tea ceremonies</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  A Tea Dance involves prayer, feasting, dancing, singing of
                  sacred songs, socializing, storytelling and sharing by Elders.
                  You can bring offering and ask for healing.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 8 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Blanket ceremonies</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  Blankets holds deep meaning and traditions. Blankets are
                  important to ceremonial celebrations such as namings, coming
                  of age, and honouring. A blanket can be used to cover the
                  heart to keep positive in and negative out. The blanket
                  signifies that you are being covered and looked after. It’s a
                  way to heal and let go. Blankets are crafted with different
                  ceremonial colours, the design tells a story of, for example,
                  compassion, healing abilities and survival.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 9 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Healing circles</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  Healing circle is a way of providing support and getting
                  support for issues and challenges such as trauma, loss and
                  grief and suicide. It a safe place to share your journey,
                  acknowledge your pain, share laughter, build trust, realize
                  you are not alone. It allows you to let go. The elders teach
                  important lessons about the sacredness of all of life, the
                  trees, water, plants and animals. Smudging allows you to
                  sanctify and cleanse yourself. The understanding and knowledge
                  gained are important for your spiritual growth, which is
                  important to maintain balance.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <!-- 10 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Naming ceremonies</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <p class="pl-4">
                  Naming ceremonies are deeply spiritual, when you get a name it
                  connects to your ancestors, it is important for familial
                  bonding and establishing kinship with our community. It helps
                  give you a sense of identity and empowerment as an indigenous
                  person. The name is sacred it help you find your way. Your
                  spiritual name has meaning – it explains something significant
                  to help you walk on your journey.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
      </div>
      <!-- Bottom note -->
      <p class="pt-4 mt-4 text-base">
        <span class="text-blue">Note</span>: It is important to note that each
        Nation has its own unique relationship with the land and ceremonies vary
        among Nations, if you are, for example, Blackfoot, Cree, Ojibwe or Dene
        the ceremonies and practices may have some variation, talk to an elder
        to learn more.
      </p>
    </div>
  </section>

  <FooterCard
    img="try-another-coping.png"
    text="View Tools & Resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>Try another coping strategy</template>
    <template v-slot:content>
      Different coping strategies resonate with different people.<br />
      Discover more coping strategies to add to your toolkit.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Collapsible from "@/components/Collapsible.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "ExploreCulturalHealingPractices",
  components: {
    Banner,
    Collapsible,
    FooterCard,
  },
};
</script>

<style scoped></style>
